/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, config, mappedAudioSprites } from '../../config';
import { EventTypes, GameMode, GraphQLErrorsType, ISettledBet, IUserBalance } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  placeBetGql,
  replayBetGql,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsSuspended,
  setIsTimeoutErrorMessage,
  setIsTransitionStarted,
  setLastRegularWinAmount,
  setReplayBet,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
  stressfulGql,
} from '../../gql';
import { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import SlotMachine from '../../slotMachine';
import Tween from '../../slotMachine/animations/tween';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import {
  canPressSpin,
  formatNumber,
  isFreeRoundBonusMode,
  isFreeSpinsMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';

import { IPlaceBetInput } from './d';

const handleChangeRestriction = (): void => {
  if (setBrokenGame()) {
    setIsSuspended(false);
    AudioHowl.unSuspend();
    AudioHowl.changeRestriction(false, []);
    eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    AudioHowl.changeRestriction(false, [
      { type: ISongs.BaseGameBGM_Base },
      { type: ISongs.BaseGameBGM_Melo, volume: 0 },
    ]);
  }
};

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);
  const fadeOutCallback = () => {
    AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);
  };

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<any>): void => {
    const metadata = placeBet.balance.settled.metadata || placeBet.balance.placed.metadata;

    if (metadata) {
      setStressful({
        show: true,
        message: metadata.data.messageBody,
        title: metadata.data.messageTitle,
        type: metadata.type === 'BLOCKING' ? 'blocking' : 'info',
      });
    }

    eventManager.emit('placeBetCompleted');
    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(placeBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    if (isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.SET_LAST_BET_RESULT_AFTER_FREE_SPINS);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      const thirdWinInRow = _.reduce(lastThreeSpins, (acc, item) => acc && item);
      setGameHistory(lastThreeSpins);
      if (placeBet.bet.coinAmount * placeBet.bet.lineSet.coinAmountMultiplier * 5 <= win && !thirdWinInRow) {
        AudioHowl.fadeIn(3000, ISongs.BaseGameBGM_Melo);
      }
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) &&
    !setCurrentBonus().isActive &&
    setCurrentBonus().rounds === setCurrentBonus().currentRound;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      eventManager.emit('placeBetCompleted');

      if (error.graphQLErrors.some((err) => err.extensions?.type === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
      }
    },
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError(error) {
      eventManager.emit('placeBetCompleted');
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      const freeRoundBonus = setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;

      const spinState = SlotMachine.getInstance().state;
      if (
        spinState !== SlotMachineState.IDLE ||
        (!isFreeSpinsMode(setGameMode()) && (!setBrokenGame() || setGameMode() === GameMode.FREE_ROUND_BONUS))
      ) {
        SlotMachine.getInstance().spin(isTurboSpin);
      }

      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) return;
        if (!isFreeRoundBonusMode(setGameMode())) {
          setWinAmount(0);
          eventManager.emit(EventTypes.UPDATE_WIN_VALUE, formatNumber(setCurrency(), 0, showCurrency(setCurrency())));
        }

        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const payload = {
            variables: {
              input: {
                slotId,
                coinAmount: setCoinAmount(),
                coinValue: setCoinValue(),
                lineSetId: lineSet.id,
              } as IPlaceBetInput,
            },
          };
          if (freeRoundBonus) {
            payload.variables.input.userBonusId = setCurrentBonus().id;
          }

          fnGet(payload);
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioHowl.stop({ type: ISongs.SFX_UI_Close });
        AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioHowl.isRestricted) {
          handleChangeRestriction();
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioHowl.isRestricted) {
        AudioHowl.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => {
            handleChangeRestriction();
            setIsShowSoundToast(false);
          },
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      clearTimeout(timeout);
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSet.id,
              userBonusId: setCurrentBonus().id,
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioHowl.play({ type: ISongs.SFX_UI_SpinStart });
    };
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus =
        setIsStopOnFeatureWin() &&
        setCurrentBonus().isActive &&
        setCurrentBonus().gameMode !== GameMode.FREE_ROUND_BONUS;

      const stopOnWin = setIsStopOnAnyWin() && setLastRegularWinAmount() > 0;

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (!isFreeSpinsMode(setGameMode()) && setIsFreeSpinsWin()) {
      return;
    }
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (data?.isEnabledSpaceSpin) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        }

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus()?.currentRound || 0,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isPopupOpened: setIsPopupOpened(),
            transitionStarted: setIsTransitionStarted(),
          })
        ) {
          return;
        }

        if (data?.isEnabledSpaceSpin && isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (data?.isEnabledSpaceSpin && progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes, (acc, item) => acc && item);
    const stop = !_.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
      AudioHowl.fadeIn(500, ISongs.BaseGameBGM_Melo);
    }

    if (stop) {
      AudioHowl.fadeOut(3000, ISongs.BaseGameBGM_Melo);
    } else {
      timeout = setTimeout(fadeOutCallback, 30000);
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
